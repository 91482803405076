var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_vm._l((_vm.feed),function(section,sectionIndex){return [(section.blocks && section.blocks.length > 0)?_c('ContentLayout',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isSectionShown(section)),expression:"isSectionShown(section)"}],key:sectionIndex,class:{
        'display-md-none': section.targeting.screenSize === 'MOBILE',
        'display-none display-md-flex': section.targeting.screenSize === 'DESKTOP',
      },style:(!_vm.lazyLoadedList.includes(sectionIndex) ? 'height: 500px' : 'height: auto'),attrs:{"data-section-index":sectionIndex,"items":section.blocks,"allow-full-width":true},scopedSlots:_vm._u([{key:"item",fn:function({ item: block, itemIndex: blockIndex }){return [(_vm.lazyLoadedList.includes(sectionIndex) || _vm.skipLazyLoad(block))?_c('HeadlineBlocks',{key:blockIndex,attrs:{"data":{
            ...block.data,
            ...block.attributes.data,
          },"tracking":{ ..._vm.getTrackingData(section, block) },"block-type":block.type,"settings":!!block.attributes.settings ? block.attributes.settings : {}}}):_vm._e()]}}],null,true)},'ContentLayout',_vm.getSectionSettings(section.attributes.settings),false)):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }