import Vue from 'vue';
import { Settings } from '@root/common/types/lazyLoader';

interface Data {
  lazyLoadedList: number[];
  firstIndexLoad: boolean;
}

interface Methods {
  createObserver: () => IntersectionObserver | null;
  fillLazyLoadedList: (layoutPosition: number) => void;
}

function createBaseComponent(settings: Settings) {
  const dataId = `data-${settings.lazyLoader.dataId}`;

  return Vue.extend<Data, Methods, unknown, unknown>({
    data() {
      return {
        lazyLoadedList: [],
        firstIndexLoad: false,
      };
    },
    created() {
      this.lazyLoadedList = settings.preLoadedDomIds;
    },
    mounted() {
      const $elements = document.querySelectorAll(`[${dataId}]`);
      const isObserverSupported = !!window.IntersectionObserver;

      if (!$elements || $elements.length === 0) {
        return;
      }

      if (isObserverSupported) {
        this.$nextTick(() => {
          $elements.forEach(($element) => {
            const observer = this.createObserver();
            observer?.observe($element);
          });
        });
      } else {
        $elements.forEach(($element) => {
          const layoutPosition = Number($element.getAttribute(dataId));
          this.lazyLoadedList.push(layoutPosition);
        });
      }
    },
    methods: {
      createObserver() {
        if (typeof window === 'undefined') {
          return null;
        }

        return new window.IntersectionObserver(([entry]) => {
          if (!entry.isIntersecting) {
            return;
          }

          const layoutPosition = Number(entry.target.getAttribute(dataId));
          const isInList = this.lazyLoadedList.includes(layoutPosition);

          // If user loads content in the middle of the page then fill array with all the previous data
          if (!this.firstIndexLoad) {
            this.fillLazyLoadedList(layoutPosition);
            return;
          }

          // Load next index
          if (!isInList) {
            this.lazyLoadedList.push(layoutPosition);
          }
        }, settings.observer);
      },
      fillLazyLoadedList(layoutPosition) {
        this.firstIndexLoad = true;
        const biggestNumber = Math.max(...this.lazyLoadedList);

        if (layoutPosition <= biggestNumber) {
          return;
        }

        // Fill the gap between the biggest index in the array and currently loaded position index
        const inBetween = [];
        for (let i = biggestNumber + 1; i < layoutPosition; i++) {
          inBetween.push(i);
        }

        this.lazyLoadedList = [...this.lazyLoadedList, ...inBetween, layoutPosition];
      },
    },
  });
}

function createDebugComponent(settings: Settings) {
  const BaseComponent = createBaseComponent(settings);

  return BaseComponent.extend({
    watch: {
      lazyLoadedList() {
        const list = [...this.lazyLoadedList].sort((a, b) => a - b);
        console.log('list', list);
      },
    },
  });
}

export { createBaseComponent, createDebugComponent };
