
import Vue from 'vue';
import { Data as HeadlinesData, DataWithHref as HeadlinesDataWithHref, HeadlineSettings } from '@root/modules/headlines/types/headlines';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';
import { BlockCollection } from '@headlines/components/block';
import { TrackingData } from '@root/common/types/fragment';

type HeadlineItems = {
  items: HeadlinesData[];
};

interface Props {
  blockType: string;
  data: HeadlineItems;
  settings: HeadlineSettings;
  tracking: TrackingData | undefined;
}

interface Computed {
  getHeadlinesData: unknown;
  blockKey: number;
  domain: string;
}

interface Methods {
  getHeadlinesWithHref: (data: HeadlinesData[]) => HeadlinesDataWithHref[];
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    ...BlockCollection,
  },
  props: {
    blockType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    tracking: {
      type: Object,
      required: false,
    },
  },
  computed: {
    getHeadlinesData() {
      const { data } = this;

      // Add href support for headlines
      if (data.items) {
        data.items = this.getHeadlinesWithHref(data.items);
      }

      return data;
    },
    // Watch adFree package state to re-render ad block type component
    blockKey() {
      const adFree = this.$store.state.piano.access.adFree;
      return this.blockType === 'BlockType26' ? +adFree : 0;
    },
    domain() {
      return this.$channelConfig('settings').domain;
    },
  },
  methods: {
    getHeadlinesWithHref(items) {
      const articlesLinksHash = this.settings.articlesLinksHash;

      const { domain, locale } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

      const itemsWithHref = items.map((headline) => {
        if (headline.relatedArticles?.items && headline.relatedArticles.items.length > 0) {
          for (let i = 0; i < headline.relatedArticles.items.length; i++) {
            if (headline.relatedArticles.items[i]) {
              headline.relatedArticles.items[i] = getHeadlineWithHref({
                headline: headline.relatedArticles.items[i] as HeadlinesData,
                domain,
                locale,
                fakeDomainEnabled,
                articlesLinksHash,
                tracking: this.tracking,
              });
            }
          }
        }

        return getHeadlineWithHref({ headline, domain, locale, fakeDomainEnabled, articlesLinksHash, tracking: this.tracking });
      });
      return itemsWithHref;
    },
  },
});
