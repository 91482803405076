import Vue from 'vue';
import { Settings } from '@root/common/types/lazyLoader';
import { createBaseComponent, createDebugComponent } from './LazyLoaderComponent';

interface ComponentCreator {
  create(): typeof Vue;
}

export default class LazyLoader implements ComponentCreator {
  private _settings: Settings = {
    preLoadedDomIds: [],
    observer: {},
    lazyLoader: { debug: false, dataId: 'lazy-observer' },
  };

  private componentSelector(settings: Settings) {
    switch (settings.lazyLoader.debug) {
      case false:
        return createBaseComponent(settings);
      case true:
        return createDebugComponent(settings);
      default:
        throw new Error('Component type not found!');
    }
  }

  public setObserverSettings(settings: Settings['observer']) {
    this._settings.observer = settings;
  }

  public setPreLoadedDomIds(settings: Settings['preLoadedDomIds']) {
    this._settings.preLoadedDomIds = settings;
  }

  public setLazyLoaderSettings(settings: Partial<Settings['lazyLoader']>) {
    this._settings.lazyLoader = {
      ...this._settings.lazyLoader,
      ...settings,
    };
  }

  public create() {
    const settings = this._settings;
    return this.componentSelector(settings);
  }
}
